import Home from '../pages/Home.vue'
import PageNotFound from '../pages/404.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { COMPANIES } from '../constants/companies';

const routes = [
  {
    path: "/",
    name: "Inicio",
    component: Home,
    beforeEnter(to) {
      /**
       * This block of code will be commented because 
       * it is required to place a "companyId" by default
       */
      // const companyId = to.query.companyId;
      // if (!companyId) return "/page-not-found";
      return COMPANIES.includes(process.env.VUE_APP_COMPANY_ID) ? true : '/page-not-found';
    },
  },
  {
    path: "/page-not-found",
    name: "404 - Page Not Found",
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(function (to, from, next) {
  if (!!to.hash === false) {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
  next()
})
export default router
