<script setup>
import axios from "axios";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { RULES } from "../constants/rules";
import { cities } from "../constants/cities";
import { HttpClient } from "../services/http";
import { useVuelidate } from "@vuelidate/core";
import ValidateLabel from "./ValidateLabel.vue";
import { useSweetalert } from "../composables/useSweetalert";
import { documentTypes, contributorTypes } from "../constants/catalogs";

const props = defineProps({
  logoURL: {
    type: String,
    required: true,
  },
});

const route = useRoute();
const http = HttpClient.getInstance();
const { showErrorToast, showSuccessToast } = useSweetalert();

const COMPANY_ID = process.env.VUE_APP_COMPANY_ID;

const selectCity = ref("");
const optionsDocumentTypes = ref([]);
const optionsContributorTypes = ref([]);

const isLoading = ref(false);
const terms = ref({
  verifyInformation: false,
  acceptDataManagement: false,
});
const user = ref({
  idType: "",
  idNumber: "",
  name: "",
  lastName: "",
  email: "",
  city: "",
  address: "",
  regime: "",
  plate: "",
  department: "",
  companyId: COMPANY_ID,
});

const v$ = useVuelidate(RULES, { terms, user });

const optionsCity = ref([]);

function onSelect(zipCode) {
  const record = cities.find((record) => record.zipCode === zipCode);
  user.value.city = record.cityDANE;
  user.value.department = record.departmentDANE;
  selectCity.value = `${record.city} - ${record.department}`;
}

function onSearch(searchText) {
  const filteredCities = [];
  const searchTerm = searchText.toLowerCase().trim();

  for (const record of cities) {
    const cityLowerCase = record.city.toLowerCase();
    const cityUpperCase = record.city.toUpperCase();

    if (!cityLowerCase.includes(searchTerm)) continue;

    filteredCities.push({
      value: record.zipCode,
      label: `${cityUpperCase} - ${record.department}`,
    });
  }

  optionsCity.value = filteredCities;
}

function cleanLicensePlate(licensePlate) {
  let cleanPlate = licensePlate.replace(/\s/g, "").replace(/[^\w\s]/gi, "");
  cleanPlate = cleanPlate.toUpperCase();
  return cleanPlate;
}

async function registerUser() {
  try {
    if (isLoading.value) return;

    isLoading.value = true;

    const isFormValid = await v$.value.$validate();

    if (!isFormValid) return;
    if (!terms.value.verifyInformation || !terms.value.acceptDataManagement) return

    const data = { ...user.value, plate: cleanLicensePlate(user.value.plate) };

    await http.post("/client", data);
    showSuccessToast("Se ha registrado exitosamente");
    clearForm();
    v$.value.$reset();
  } catch (error) {
    const translatedMessage = await axios.get(
      `https://api.mymemory.translated.net/get?q=${error.response.data.msg}&langpair=en|es`
    );
    const message =
      translatedMessage.data.responseData.translatedText ??
      "Hubo un error, intenta nuevamente";
    showErrorToast(message);
  } finally {
    isLoading.value = false;
  }
}

function clearForm() {
  user.value = {
    idType: "",
    idNumber: "",
    name: "",
    lastName: "",
    email: "",
    city: "",
    address: "",
    regime: "",
    plate: "",
    department: "",
    companyId: "SOACHA-BAN",
  };
  selectCity.value = "";
  terms.value = {
    verifyInformation: false,
    acceptDataManagement: false,
  };
}

function mapDocumentTypesToOptions() {
  optionsDocumentTypes.value = documentTypes.map(({ type, id }) => ({
    label: type,
    value: id,
  }));
}

function mapContributorTypesToOptions() {
  optionsContributorTypes.value = contributorTypes.map(({ type, id }) => ({
    label: type,
    value: id,
  }));
}

onMounted(() => {
  mapDocumentTypesToOptions();
  mapContributorTypesToOptions();
});
</script>

<template>
  <div class="login-register form-style-2" data-bg-image="">
    <div class="container">
      <div class="form-wrap banner-style-6">
        <div class="row">
          <div class="col-12">
            <div class="section-area">
              <img :src="logoURL" />
              <h2 class="section-title mt-5">Facturación Electrónica</h2>
              <p class="section-desc">
                Registrate en el formulario, verifica tu transacción y recibe tu
                factura al correo.
              </p>
            </div>
          </div>

          <div class="col-lg-7 ms-auto me-auto">
            <form id="contact-form" class="form" @submit.prevent="registerUser">
              <!-- step one -->
              <div>
                <h5 class="title">Paso 1: Información personal</h5>

                <div class="form-field">
                  <label>Tipo de identificación:</label>
                  <a-select
                    v-model:value="user.idType"
                    size="large"
                    style="width: 100%"
                    :options="optionsDocumentTypes"
                  />
                  <span v-if="v$.user.idType.$error" class="text-danger fs-6">
                    Escoja una tipo de identificación
                  </span>
                </div>

                <div class="form-field">
                  <label>Número de identificación:</label>
                  <a-input
                    v-model:value="user.idNumber"
                    placeholder="Escriba su número de identificación"
                    size="large"
                  />
                  <ValidateLabel :v$="v$['user']['idNumber']" />
                </div>

                <div class="form-field">
                  <label>Nombre:</label>
                  <a-input
                    v-model:value="user.name"
                    placeholder="Escriba su nombre"
                    size="large"
                  />
                  <ValidateLabel :v$="v$['user']['name']" />
                </div>

                <div class="form-field">
                  <label>Apellido:</label>
                  <a-input
                    v-model:value="user.lastName"
                    placeholder="Escriba su apellido"
                    size="large"
                  />
                  <ValidateLabel :v$="v$['user']['lastName']" />
                </div>

                <div class="form-field">
                  <label>Correo:</label>
                  <a-input
                    v-model:value="user.email"
                    placeholder="Escriba su correo"
                    size="large"
                    type="email"
                  />
                  <ValidateLabel :v$="v$['user']['email']" />
                </div>

                <div class="form-field">
                  <label>Ciudad:</label>
                  <a-auto-complete
                    v-model:value="selectCity"
                    :options="optionsCity"
                    style="width: 100%"
                    size="large"
                    @select="onSelect"
                    @search="onSearch"
                  >
                    <a-input-search
                      size="large"
                      placeholder="Busque y seleccione su ciudad"
                    />
                  </a-auto-complete>
                  <ValidateLabel :v$="v$['user']['city']" />
                </div>

                <div class="form-field">
                  <label>Dirección:</label>
                  <a-input
                    v-model:value="user.address"
                    placeholder="Escriba su direccion"
                    size="large"
                  />
                  <ValidateLabel :v$="v$['user']['address']" />
                </div>
              </div>

              <!-- step two -->
              <div>
                <h5 class="title mt-5">Paso 2: Información tributaria</h5>

                <div class="form-field">
                  <label>Regimen:</label>
                  <a-select
                    v-model:value="user.regime"
                    style="width: 100%"
                    size="large"
                    :options="optionsContributorTypes"
                  />
                  <span v-if="v$.user.regime.$error" class="text-danger fs-6">
                    Escoja un tipo de regimen
                  </span>
                </div>
              </div>

              <!-- step three -->
              <div>
                <h5 class="title mt-5">Paso 3: Información del vehiculo</h5>

                <div class="form-field">
                  <label>Placa:</label>
                  <a-input
                    v-model:value="user.plate"
                    placeholder="Escriba su placa"
                    size="large"
                  />
                  <ValidateLabel :v$="v$['user']['plate']" />
                </div>
              </div>

              <div class="form-check pt-4 pb-2">
                <input
                  v-model="terms.verifyInformation"
                  type="checkbox"
                  class="form-check-input"
                />
                <label class="form-check-label" for="exampleCheck1"
                  ><em
                    >He verificado y estoy de acuerdo con información
                    relacionada con la transacción para generar facturación
                    electrónica.</em
                  ></label
                >
              </div>

              <div class="form-check pb-4">
                <input
                  v-model="terms.acceptDataManagement"
                  type="checkbox"
                  class="form-check-input"
                />
                <label class="form-check-label" for="exampleCheck2"
                  ><em
                    >Acepto la <a>politica de tratamiento de datos</a></em
                  ></label
                >
              </div>

              <div class="d-grid gap-2">
                <button
                  type="submit"
                  value="submit"
                  class="btn btn-primary"
                  name="submit"
                  :disabled="isLoading"
                >
                  <span v-if="!isLoading">Enviar información</span>

                  <div v-else class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <p class="form-messege"></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
